import { useMemo } from 'react';
import { TripRange, DestinationDetails } from '../DetailCard.types';
import { DetailContainer, DetailLabel, DetailWrapper, SubDetail } from './DetailSection.styles';
import { format } from 'date-fns'
import { TripTypeIR } from 'pages/RequestToBook/enums/TripType.enum';

const createTripRange = (tripRange: TripRange) => {
	return (
		<>
			<time dateTime={tripRange.checkInDate}>{tripRange.checkInDate}</time>
			&ensp;-&ensp;
			<time dateTime={tripRange.checkOutDate}>{tripRange.checkOutDate}</time>
		</>
	);
};

const createDetail = (key, label, value) => {
	return (
		<DetailContainer key={key}>
			<DetailLabel>{label}</DetailLabel>

			{value}
		</DetailContainer>
	);
};

const createLayerDetail = (key, label, value, subText: string) => {
	return (
		<div>
			{createDetail(key, label, value)}
			<SubDetail>{subText}</SubDetail>
		</div>
	);
};

const labelCaseHandler = (text: string) => {
	let splitText = text.split(' ');
	const labelsToUpper = ['id']

	for(let i = 1; i < splitText.length; i++) {
		splitText[i] = splitText[i].charAt(0).toLowerCase() + splitText[i].slice(1);

		if(labelsToUpper.includes(splitText[i].toLowerCase())){
			splitText[i] = splitText[i].toUpperCase()
		}
	}

	return splitText.join(' ');
};

export const DetailSection: React.FunctionComponent<{ details: DestinationDetails }> = (details) => {
	const keys = Object.keys(details);

	const detailMemo = useMemo(
		() =>
			keys.map((current, index) => {
				let label = current.split(/(?=[A-Z])(?=\D{2,})/).join(' ');
				label = label.charAt(0).toUpperCase() + label.slice(1);

				label = labelCaseHandler(label);

				let detailJSX;
				const reserveDetail = () => {
					detailJSX = createDetail(index, label, `${details[current].passDays} Pass Days`);
				};
				switch (current) {
					case 'dates':
						detailJSX = createDetail(index, label, createTripRange(details[current]));
						break;
					case 'arrivingOn':
					case 'lengthOfStay':
						detailJSX = createDetail(index, label, `${details[current]} nights`);
						break;
					case 'estValue':
						label = label.replace(' ', '. ');
						detailJSX = createDetail(index, label, details[current].replace(/\B(?=(\d{3})+(?!\d))/g, ','));
						break;
					case 'reserveWith':
						if(!details[current].inspiratoRewards || details[current].inspiratoRewards === TripTypeIR.Invited){
							if(details[current].passDays > 0 && (!details[current].submitted || details[current].rtb)){
								reserveDetail();
								break;
							}
							else {
								break;
							}
						}
						break;
					case 'reservedWith':
						if (details[current].passDays > 0 && details[current].submitted && !details[current].rtb) {
							reserveDetail();
							break;
						} else {
							break;
						}
					case 'reserveNextOn':
						if (details[current].passDays > 0 && !details[current].inspiratoRewards) {
							detailJSX = createDetail(index, label, format(details[current].nextBookingDate, 'M/D/YYYY'));
							break;
						} else {
							break;
						}
					case 'bookYourNextTrip':
						if (details[current].passDays > 0 && !details[current].inspiratoRewards) {
							detailJSX = createDetail(index, label, `${format(details[current].nextBookingDate, 'M/D/YYYY')}`);
							break;
						} else {
							break;
						}
					case 'appliedPassDays':
						if(!details[current].inspiratoRewards) {
							detailJSX = createDetail(index, 'Applied Pass Days', `${details[current].passDaysApplied}`);
						} else {
							break;
						}
						break;


					default:
						detailJSX = createDetail(index, label, details[current]);
				}

				return detailJSX;
			}),
		[details],
	);
	return <DetailWrapper>{detailMemo}</DetailWrapper>;
};
